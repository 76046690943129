/*
 |-----------------------------------------------------------------------------
 | components/helpers/Website/Website.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import * as IWebsite from './types';

const Website: React.FC<IWebsite.IProps> = ({ name, url }) => {
	return (
		<span itemScope itemType="https://schema.org/WebSite">
			<a
				href={url}
				itemProp="url"
				rel="external noopener noreferrer"
				target="_blank"
				title={`Visit ${name}`}
			>
				{url}
			</a>
		</span>
	);
};

export default Website;
