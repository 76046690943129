/*
 |-----------------------------------------------------------------------------
 | components/helpers/Phone/Phone.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { replace, trimStart } from 'lodash';

import * as IPhone from './types';

const Phone: React.FC<IPhone.IProps> = ({ number }) => {
	let sanitizedNumber;
	sanitizedNumber = replace(number, /\s/g, '');
	sanitizedNumber = replace(sanitizedNumber, '+44', '');
	sanitizedNumber = replace(sanitizedNumber, '(0)', '');
	sanitizedNumber = trimStart(sanitizedNumber, '0');

	return (
		<span itemProp="telephone">
			<a href={`tel:+44${sanitizedNumber}`}>{number}</a>
		</span>
	);
};

export default Phone;
