/*
 |-----------------------------------------------------------------------------
 | components/helpers/Email/Email.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React, { useEffect } from 'react';

import * as IEmail from './types';

const Email: React.FC<IEmail.IProps> = ({ address }) => {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const MailtoUI = require('../../../node_modules/mailtoui/dist/mailtoui-min');
		MailtoUI.run();
	}, []);

	return (
		<span itemProp="email">
			<a className="mailtoui" href={`mailto:${address}`}>
				{address}
			</a>
		</span>
	);
};

export default Email;
