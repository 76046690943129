/*
 |-----------------------------------------------------------------------------
 | utils/parseHtml.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import HTMLReactParser from 'html-react-parser';

/**
 * Some description...
 *
 * @param string
 *
 * @returns {ReturnType<typeof domToReact>}
 */

const parseHtml = (string: string) => {
	return HTMLReactParser(string);
};

export { parseHtml };
