/*
 |-----------------------------------------------------------------------------
 | components/molecules/ContactPoint/ContactPoint.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IContactPoint from './types';
import { parseHtml } from '@/utils/parseHtml';

import Email from '@/helpers/Email';
import Phone from '@/helpers/Phone';
import Website from '@/helpers/Website';

import styles from './ContactPoint.module.scss';

const variants = {
	body: 'body',
	footer: 'footer',
};

const ContactPoint: React.FC<IContactPoint.IProps> = ({
	className,
	email,
	hideLabels,
	phone,
	variant,
	website,
}) => {
	const classes = classNames(get(styles, variants[variant]), className);

	// ray('Debug molecule ContactPoint:', {
	// 	classname: classname,
	// 	email: email,
	// 	hideLabels: hideLabels,
	// 	phone: phone,
	// 	variant: variant,
	// 	website: website,
	// }).blue();

	return (
		<ul className={classes}>
			{email && (
				<li>
					{hideLabels
						? parseHtml(
								'<span class="u-screen-reader">Email:</span>'
						  )
						: 'Email: '}
					<Email address={email} />
				</li>
			)}
			{phone && (
				<li>
					{hideLabels
						? parseHtml(
								'<span class="u-screen-reader">Phone:</span>'
						  )
						: 'Phone: '}
					<Phone number={phone} />
				</li>
			)}
			{website && (
				<li>
					{hideLabels
						? parseHtml(
								'<span class="u-screen-reader">Website:</span>'
						  )
						: 'Website: '}
					<Website
						name={get(website, 'name')}
						url={get(website, 'url')}
					/>
				</li>
			)}
		</ul>
	);
};

export default ContactPoint;
